<script setup lang="ts" generic="T">
import type { UISliderEditorialWithArrows } from './UISliderEditorialWithArrows.props'
import { Navigation, A11y, Keyboard } from 'swiper/modules'

const props = withDefaults(defineProps<UISliderEditorialWithArrows<T>>(), {
  hasNoSpaceBetween: false,
  cardDimensions: 'small',
  type: 'slider',
  mobileCentered: false,
  padding: false,
})
const { ts } = useI18n()
const id = useId()

const items = computed(() => {
  if (props.type === 'default') return props.items

  const maxSlidesPerView = 5
  let _tempArray = props.items

  while (_tempArray.length < maxSlidesPerView * 2) {
    _tempArray = _tempArray.concat(_tempArray)
  }
  return _tempArray
})
const visibleNavigationPanel = ref<null | 'L' | 'R'>(null)

const mousemoveHandler = (e: MouseEvent) => {
  if (e.pageX < window.screen.width / 2) {
    visibleNavigationPanel.value = 'L'
  } else {
    visibleNavigationPanel.value = 'R'
  }
}

const isSlider = computed(() => {
  return props.type === 'slider'
})

const _slidesPerViewDesktop = computed(() => {
  if (props.cardDimensions === 'small') {
    return isSlider.value ? 4 : 4
  } else if (props.cardDimensions === 'medium') {
    return isSlider.value ? 3 : 3
  } else if (props.cardDimensions === 'large') {
    return isSlider.value ? 1.2 : 3
  }
})
const _slidesPerViewMobile = ref<number>(1.3)

const swiperEl = ref<HTMLElement>()
let resizeObserver: any = null

const updateSwiperHeight = () => {
  const swiperContainer = document.querySelector('.image-slider-container')
  if (swiperContainer && swiperEl.value) {
    swiperEl.value.style.setProperty(
      '--editorial-slider-image-height',
      `${swiperContainer.clientHeight}px`
    )
  }
}

onMounted(() => {
  updateSwiperHeight()

  const swiperContainer = document.querySelector('.image-slider-container')
  if (swiperContainer) {
    resizeObserver = new ResizeObserver(() => {
      updateSwiperHeight()
    })
    resizeObserver.observe(swiperContainer)
  }
})

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})
</script>
<template>
  <div
    v-if="items && items.length"
    ref="swiperEl"
    class="slider-container relative"
    @mousemove="(e: any) => mousemoveHandler(e as MouseEvent)"
    @mouseleave="visibleNavigationPanel = null"
  >
    <!-- Navigation back -->

    <div
      v-if="isSlider"
      class="bg-background-secondary ml:flex w-xxl xl:w-4xl absolute bottom-0 left-0 top-0 z-10 hidden h-[var(--editorial-slider-image-height,100%)] items-center justify-center opacity-0 transition-opacity duration-500"
      :class="visibleNavigationPanel === 'L' && `opacity-100`"
    >
      <button
        :class="`swiper-button-prev-${id}`"
        class="flex h-full w-full items-center justify-center"
        :aria-label="ts('aria-labels.prevSlide')"
      >
        <DefaultIconsChevronLeft
          :aria-hidden="true"
          class="icon-editorial-slider"
        />
      </button>
    </div>
    <!-- Navigation next -->
    <div
      v-if="isSlider"
      class="bg-background-secondary ml:flex w-xxl xl:w-4xl absolute bottom-0 right-0 top-0 z-10 hidden h-[var(--editorial-slider-image-height,100%)] items-center justify-center opacity-0 transition-opacity duration-500"
      :class="visibleNavigationPanel === 'R' && 'opacity-100'"
      :aria-label="ts('aria-labels.nextSlide')"
    >
      <button
        :class="`swiper-button-next-${id}`"
        class="flex h-full w-full items-center justify-center"
        :aria-label="ts('aria-labels.nextSlide')"
      >
        <DefaultIconsChevronRight
          :aria-hidden="true"
          class="icon-editorial-slider"
        />
      </button>
    </div>
    <div
      :class="{
        'carousel-container-overflowed': isSlider,
        'mx-sm md:mx-md lg:mx-xxl xl:mx-4xl': props.padding && !isSlider,
      }"
    >
      <div :class="{ 'carousel-overflowed lg:px-xxl xl:px-4xl': isSlider }">
        <Swiper
          :centered-slides="mobileCentered"
          :modules="[Navigation, A11y, Keyboard]"
          :slides-per-view="_slidesPerViewMobile"
          keyboard
          :space-between="hasNoSpaceBetween ? 0 : 1"
          :navigation="
            isSlider && {
              enabled: true,
              nextEl: `.swiper-button-next-${id}`,
              prevEl: `.swiper-button-prev-${id}`,
            }
          "
          :loop="isSlider"
          grab-cursor
          watch-slides-progress
          :initial-slide="1"
          :breakpoints="{
            768: {
              spaceBetween: hasNoSpaceBetween ? 0 : 1,
              slidesPerView: _slidesPerViewMobile,
            },
            1024: {
              slidesPerView: _slidesPerViewDesktop,
              centeredSlides: cardDimensions === 'large' ? true : false,
              spaceBetween: hasNoSpaceBetween ? 0 : 1,
            },
          }"
          class="swiper"
        >
          <SwiperSlide
            v-for="(item, index) in items"
            :key="`item__${index}`"
            v-slot="{ isVisible }"
            class="swiper-slide !h-[initial]"
          >
            <slot v-bind="{ item, index, isVisible }" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:deep(.swiper) {
  width: 100%;
  height: 100%;
}
.carousel-container-overflowed {
  @apply overflow-hidden;
  .carousel-overflowed {
    @apply overflow-visible;
    .swiper {
      @apply overflow-visible;
    }
  }
}
.icon-editorial-slider {
  color: var(--colorStrokePrimary);
}
</style>
